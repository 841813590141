<template>
    <b-container fluid v-if="client">
        <b-row>
            <b-col md="12" class="align-items-center justify-content-center text-center">
                <h3>Podaci o klijentu</h3>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" class="p-4 align-items-center justify-content-center">
                <table class="table b-table table-striped table-hover table-bordered text-center">
                    <thead>
                    <tr>
                        <td>
                            <b>Ime:</b> {{client.name}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Adresa:</b> {{client.address}}
                        </td>
                    </tr>
                    <tr v-if="client.phone">
                        <td>
                            <b>Telefon:</b> {{client.phone}}
                        </td>
                    </tr>
                    <tr v-if="client.township.name">
                        <td>
                            <b>Općina:</b> {{client.township.name}}
                        </td>
                    </tr>
                    <tr v-if="client.activity">
                        <td>
                            <b>Djelatnost:</b> {{client.activity.name}}
                        </td>
                    </tr>
                    <tr v-if="client.activity_secondary">
                        <td>
                            <b>Dodatna djelatnost:</b> {{client.activity_secondary.name}}
                        </td>
                    </tr>
                    <tr v-if="client.id_number">
                        <td>
                            <b>ID broj:</b> {{client.id_number}}
                        </td>
                    </tr>
                    <tr v-if="client.pdv_number">
                        <td>
                            <b>PDV broj:</b> {{client.pdv_number}}
                        </td>
                    </tr>
                    <tr v-if="client.owner.name">
                        <td>
                            <b>Vlasnik:</b> {{client.owner.name}}
                        </td>
                    </tr>
                    <tr v-if="client.owner.address">
                        <td>
                            <b>Adresa vlasnika:</b> {{client.owner.address}}
                        </td>
                    </tr>
                    <tr v-if="client.owner.jmbg">
                        <td>
                            <b>JMBG vlasnika:</b> {{client.owner.jmbg}}
                        </td>
                    </tr>
                    </thead>
                </table>
            </b-col>
            <!--<b-col md="4" class="p-4 flex-center flex-column">
                <b-row class="p-2 w-75">
                    <b-btn size="lg" variant="danger" class="w-100" v-b-modal.deleteClient>
                        Izbriši korisnika
                    </b-btn>
                </b-row>
            </b-col>-->
            <b-col md="6" class="p-4 flex-center flex-column">
                <b-row class="p-2 w-75">
                    <router-link class="w-100" :to="$route.path + '/edit'">
                        <b-btn size="lg" variant="primary" class="w-100">
                            Uredi korisnika
                        </b-btn>
                    </router-link>
                </b-row>
            </b-col>
            <b-col md="6" class="p-4 flex-center flex-column">
                <b-row class="p-2 w-75">
                    <b-btn @click="selectClient" size="lg" variant="success" class="w-100">
                        Odaberi klijenta
                    </b-btn>
                </b-row>
            </b-col>
        </b-row>
        <b-modal centered
                 id="deleteClient"
                 title="Potvrda"
                 @ok="deleteClient"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati klijenta?</p>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: 'Client',
        data() {
            return {
                client: null,
            }
        },
        methods: {
            selectClient: function() {
                this.$store.dispatch('selectClient', this.client.id);
                this.$cookie.set('selected_client', this.client.id);
            },
            deleteClient: function() {
                axiOsta.delete(api.clients + this.client.id).then(response => {
                    this.$router.push('/clients');
                })
            }
        },
        created() {
            axiOsta.get(api.clients + this.$route.params.id).then(response => {
                if (response.status === 200) {
                    this.client = response.data;
                }
            })
        }

    }
</script>
